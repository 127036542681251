
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import SatSearchInput from "@/components/SatSearchInput.vue";
import SatCard from "@/components/carousel/SatCard.vue";
import useTeamRepositories from "@/_composables/useTeamRepositories";
import useStaffRepositories from "@/_composables/useStaffRepositories";
import { TeamQueryDto, IStaff } from "@/_modules/types";
import SatIcon from "@/components/SatIcon.vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Helpers } from "@/_modules/helpers";

export default defineComponent({
  components: {
    SatSearchInput,
    SatCard,
    SatIcon,
    LoadingOutlined,
  },
  setup() {
    const staffs = ref([]);
    const searching = ref(false);
    const loading = ref(false);
    const visibleMoreSection = ref(false);
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const {
      repositories: teamsRepo,
      getStaffRepositories,
      loading: fetchingStaffs,
    } = useStaffRepositories();
    const state = reactive({
      currentPage: 1,
      lastPage: 1,
      staff: [] as IStaff[],
    });
    const helpers = Helpers;

    // const { repositories: teams } = useTeamRepositories(ref(reactive({ page: 1, size: 10 }) as TeamQueryDto))
    onMounted(async () => {
      loading.value = true;
      visibleMoreSection.value = false;

      await fetchStaff({ page: 1, size: 10 });
      loading.value = false;
      visibleMoreSection.value = true;
    });

    const fetchStaff = async (params: object) => {
      const staff = await getStaffRepositories(params);
      if (staff) {
        const { data, currentPage, lastPage } = staff.account;
        state.staff = [...state.staff, ...data];
        state.currentPage = currentPage;
        state.lastPage = lastPage;
      }
    };

    const loadMoreStaffs = async () => {
      state.currentPage = state.currentPage + 1;
      if (state.currentPage <= state.lastPage) {
        loading.value = true;
        await fetchStaff({ page: state.currentPage, size: 10 });
        loading.value = false;
      }
    };
    const onSearch = async (search: any | null) => {
      if (search?.national === "all") {
        delete search["national"];
      }

      if (search?.gender === "all") {
        delete search["gender"];
      }

      if (search?.position === 0) {
        delete search["position"];
      }

      if (search?.department === 0) {
        delete search["department"];
      }
      const objData = {
        ...search,
        page: state?.currentPage ?? 1,
        size: 10,
      };
      loading.value = true;
      state.staff = [];
      await fetchStaff(objData);
      loading.value = false;
    };
    return {
      t,
      store,
      // teams,
      ...toRefs(state),
      list: teamsRepo,
      loadMoreStaffs,
      onSearch,
      visibleMoreSection,
      loading,
      searching,
      helpers,
    };
  },
});
